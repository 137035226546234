/*******  Material changes to help scale down on density ********/

@include mat.form-field-density(-4.25);
@include mat.radio-density(-3);
@include mat.checkbox-density(-3);
@include mat.icon-button-density(-2);

mat-form-field {
  line-height: 16px !important;
}
.mat-mdc-form-field-subscript-wrapper {
  height: 18px;
  mat-error {
    position: absolute;
    top: 0px;
  }
}
.mat-mdc-form-field-infix {
  label {
    font-size: 14px !important;
    top: 20px;
  }
  label.mdc-floating-label--float-above {
    font-size: 13px !important;
  }
  input {
    margin-top: 10px;
    font-size: 14px !important;
  }
  textarea {
    margin-top: 10px;
    font-size: 14px !important;
  }
  mat-select {
    margin-top: 10px;
    font-size: 14px !important;
  }
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-floating-label {
  display: inline !important;
}

mat-list-item {
  b {
    line-height: 16px;
    font-size: 14px;
  }
  pre {
    line-height: 16px;
    font-size: 14px;
  }
  label {
    font-size: 14px;
  }
  span {
    font-size: 14px !important;
  }
}
