
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

   @media print {
    //body {transform: scale(.4);}
    @page {
      /*size: 297mm 210mm; */ /* landscape */
      //size: 550mm 711mm;
      margin: 20mm;
    }
    ////zoom: 40%;
    //
    //.ie body  {
    //  transform-origin: 0 0 !important;
    //  transform: scale(0.2) !important;
    //  padding: 3em !important;
    //}
    //*, :not(.core.ui-content) {
    //  //overflow: visible!important;
    //  overflow:hidden !important;
    //  //overflow: auto!important;
    //}
  
    *:not(.ui-content) {
      overflow: visible !important;
    }
  
    /********  Convert ag grid tables into css tables ****************/
    //div.ui-table div.ag-root{
    //  display: table!important;
    //  position: inherit;
    //}
    //
    //div.ag-header-row {
    //  display: table-header-group!important;
    //}
    //
    //div.ag-header-cell, div.ag-cell {
    // // width: unset!important;
    //  left: unset!important;
    //  display: table-cell!important;
    //  position:relative;
    //}
    //
    //div[col-id="actionMenu"] {
    //  display: none!important;
    //}
    //
    //div.ag-row {
    //  display: table-row!important;
    //}
    /********  End ag grid tables into css tables ****************/
  
    //.printEl {
    //  width: 17in;
    //  height: 22in;
    //}
    //html {
    //  font-size: 0.5em!important;
    //}
  
    .mat-mdc-menu-panel.ui-action-menu {
      display: none;
    }
  
    //do not show dropdown arrows - display none screws up the select
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    div.mat-select-arrow-wrapper {
      visibility:hidden!important;
    }
  
    //hide hints
    mat-hint {
      display:none!important;
      height:auto!important;
    }
  
    //hide the sidenav
    // .mat-drawer {
    //   width: 0!important;
    //   opacity: 0;
    //   //display none below is causing ie to not listen to any transform css above
    //   //display: none !important;
    // }
  
    //Getting scrollbars to expand and print all data
    .mat-drawer-container {
      overflow:visible!important;
      height:auto!important;
    }
    body {
      overflow: visible!important;
    }
    .core.ui-content {
      position:relative;
      top:0px!important;
    }
    header {
      position:relative;
    }
  
    //make the content next to the sidenav fill over the sidenav
    .mat-drawer-content {
      margin-left: 0 !important;
      overflow:visible!important;
      height:auto!important;
    }
    .mat-drawer-content > div {
      overflow:visible!important;
      height:auto!important;
      background: white !important;
    }
    //Show all the data in the table.
    ui-table {
      max-height:none!important;
    }
  
    html {
      overflow:visible!important;
    }
    //rebuild checkboxes since they can be hidden by selecting  to hide background graphics on the browser print
    .ag-icon-checkbox-unchecked, .ag-icon-checkbox-checked {
      width:20px;
      height:20px;
      border: 2px solid #000;
      top: 1px!important;
      display: inline-block;
    }
    .ag-icon-checkbox-checked:after {
      content: '';
      display: block;
      width: 4px;
      height: 10px;
  
      /* "Center" the checkmark */
      position:relative;
      top:2px;
      left:6px;
  
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  
    //these two for the nomination matrix grid - take out scroll bars and show all.
    .ag-root-wrapper-body.ag-layout-normal {
      height: auto!important;
    }
    .ag-root.ag-layout-normal, .ag-root.ag-layout-auto-height {
      overflow:visible!important;
    }
  
    //make the action Menu on tables small - not needed for print
    div[colid="actionMenu"][style] {
      width:0px!important;
    }
  
    //attempt to make card headers grey - currently works when you emulate print, but not on print
    .mat-mdc-card-header {
      background-color: #dcdcdc!important;
    }
  
    //hide all the buttons - they don't look good on print
    .mat-mdc-button:not(.active) {
      display: none!important;
      width: 0!important;
      height: 0!important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card-content button {
      display: none!important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    mat-card-header expandable-fullheight-button {
      display: none!important;
    }
  
    //force to print all of the pages
    //md-content {
    //  //overflow: visible!important;
    //  overflow:hidden !important;
    //}
  
    *:not(th),
    *:not(th):before,
    *:not(th):after {
      background: transparent !important;
      color: #000 !important; /* Black prints faster:
                                     http://www.sanbeiji.com/archives/953 */
      box-shadow: none !important;
      text-shadow: none !important;
    }
  
    //make sure that radios and check boxes show their selected status. - override the :after statement
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-radio-inner-circle, .mat-checkbox-checked .mat-checkbox-background{
      background: grey !important;
      -webkit-print-color-adjust: exact;
    }
  
 
    //**********  Standard stuff here
    a,
    //a:visited {
    //  text-decoration: underline;
    //}
  
    //a[href]:after {
    //  content: " (" attr(href) ")";
    //}
    //
    //abbr[title]:after {
    //  content: " (" attr(title) ")";
    //}
  
    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }
    a.mat-mdc-mini-fab {
      display: none !important;
    }
  
    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }
  
    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  
    thead {
      display: table-row-group;
    }
  
    tfoot {
      display: table-row-group;
    }
  
    table, thead, tbody, th, tr, td, tfoot {
      page-break-inside: avoid !important;
    }
  
    img {
      page-break-inside: avoid;
    }
  
    img {
      max-width: 100% !important;
    }
  
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
  
    h2,
    h3 {
      page-break-after: avoid;
    }
  }
  

