@use '@angular/material' as mat;
$blue-dark-primary: mat.define-palette(mat.$light-blue-palette, 800, 300, 900);
$blue-dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A700);
$blue-dark-warn: mat.define-palette(mat.$red-palette, A200);
$anchor-link-color: mat.get-color-from-palette($blue-dark-primary, lighter);

$blue-dark-theme: mat.define-dark-theme((
  color: (
    primary: $blue-dark-primary,
    accent: $blue-dark-accent,
    warn: $blue-dark-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@import 'low-density';

td {
  a {
    color: $anchor-link-color;
  }
}

a.light-link {
  color: $anchor-link-color;
}

epm-workflow-status > div > .step {
  border-left-color: $anchor-link-color;
}

epm-workflow-status > div > .stepPassed {
  border-top-color: $anchor-link-color;
  border-right-color: $anchor-link-color;
  border-bottom-color: $anchor-link-color;
  background: $anchor-link-color;
}

/** High Charts Theme Overrides **/
$colors: #0277BD #8bbc21 #910000 #1aadce #492970 #f28f43 #77a1e5 #c42525 #a6c96a !default;
$highlight-color-100: $anchor-link-color !default;

@import '../highcharts-base.scss';
