@use '@angular/material' as mat;
$green-dark-primary: mat.define-palette(mat.$green-palette, 700, 300, 900);
$green-dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A700);
$green-dark-warn: mat.define-palette(mat.$red-palette, A200);
$anchor-link-color: mat.get-color-from-palette($green-dark-primary, lighter);

$green-dark-theme: mat.define-dark-theme((
  color: (
    primary: $green-dark-primary,
    accent: $green-dark-accent,
    warn: $green-dark-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@import 'low-density';

epm-workflow-status > div > .step {
  border-left-color: $anchor-link-color;
}

epm-workflow-status > div > .stepPassed {
  border-top-color: $anchor-link-color;
  border-right-color: $anchor-link-color;
  border-bottom-color: $anchor-link-color;
  background: $anchor-link-color;
}

/** High Charts Theme Overrides **/
$colors: #388E3C #8bbc21 #910000 #1aadce #492970 #f28f43 #77a1e5 #c42525 #a6c96a !default;
$highlight-color-100: $anchor-link-color !default;

@import '../highcharts-base.scss';
