/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

//If we need the quill editor, uncomment these entries for ngx-quill
//@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
//@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');
// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../../libs/shared-ui/src/lib/theme/styles/base';
@import '../../../libs/shared-ui/src/lib/theme/styles/theme';
@import '../../../libs/shared-ui/src/lib/theme/styles/libs-override';

@import "../../../libs/shared-ui/src/lib/theme/styles/grid-overrids.scss";
@import "print.scss";
@import "app.scss";

// @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); --removing to replace with below
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../../../libs/shared-ui/resources/font/material-icons.woff2') format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }