//----------Google Maps---------------
.sebm-google-map-container {
  height: 490px;
  height: calc(100vh - 144px);
}

//----------Leaflet Maps---------------
#leaflet-map {
  width: 100%;
  height: 490px;
  height: calc(100vh - 144px);
}
