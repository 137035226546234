@use '@angular/material' as mat;
$indigo-light-primary: mat.define-palette(mat.$indigo-palette, 800, 300, 900);
$indigo-light-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$indigo-light-warn: mat.define-palette(mat.$red-palette, 500);
$anchor-link-color: mat.get-color-from-palette($indigo-light-primary, lighter);

$indigo-light-theme: mat.define-light-theme((
  color: (
    primary: $indigo-light-primary,
    accent: $indigo-light-accent,
    warn: $indigo-light-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@import 'low-density';

epm-workflow-status > div > .step {
  border-left-color: $anchor-link-color;
}

epm-workflow-status > div > .stepPassed {
  border-top-color: $anchor-link-color;
  border-right-color: $anchor-link-color;
  border-bottom-color: $anchor-link-color;
  background: $anchor-link-color;
}

/** High Charts Theme Overrides **/
$colors: #283593 #fc0000 #FF6F91 #8C87CC #FFC75F #f15c80 #e4d354 #2b908f #f45b5b #2d6d68;

// Neutral colors, grayscale by default. The default colors are defined by mixing the background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: #000; // Strong text.
$neutral-color-80: #000; // Main text and some strokes.
$neutral-color-60: #000; // Axis labels, axis title, connector fallback.
$neutral-color-40: #666; // Credits text, export menu stroke.
$neutral-color-20: #999999; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: #666; // Grid lines etc.
$neutral-color-5: #888; // Minor grid lines etc.lighter
$neutral-color-3: #f7f7f7; // Tooltip backgroud, button fills, map null points.
 
// Colored, shades of blue by default
$highlight-color-100: $anchor-link-color; // Drilldown clickable labels, color axis max color.

$highlight-color-20: #666; // Ticks and axis line.
$highlight-color-10: #666; // Pressed button, color axis min color.
 

@import '../highcharts-base.scss';
