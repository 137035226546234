
//** High Charts Theme
// Colors for data series and points.
$colors: #0277BD #0d233a #8bbc21 #910000 #1aadce #492970 #f28f43 #77a1e5 #c42525 #a6c96a !default;

// Chart background, point stroke for markers and columns etc
$background-color: transparent !default;
$highcharts-font-color: #fff !default;
// Neutral colors, grayscale by default. The default colors are defined by mixing the background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: $highcharts-font-color !default; // Strong text.
$neutral-color-80: $highcharts-font-color !default; // Main text and some strokes.
$neutral-color-60: $highcharts-font-color !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: #999999 !default; // Credits text, export menu stroke.
$neutral-color-20: #cccccc !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: #666 !default; // Grid lines etc.
$neutral-color-5: #888 !default; // Minor grid lines etc.lighter
$neutral-color-3: #333 !default; // Tooltip backgroud, button fills, map null points.

// Colored, shades of blue by default
$highlight-color-100: $anchor-link-color !default; // Drilldown clickable labels, color axis max color.
$highlight-color-80: #335cad !default; // Selection marker, menu hover, button hover, chart border, navigator series.
$highlight-color-60: #6685c2 !default; // Navigator mask fill.
$highlight-color-20: #666 !default; // Ticks and axis line.
$highlight-color-10: #e6ebf5 !default; // Pressed button, color axis min color.

// Fonts
$title-font-size: 1.5em !default;
$subtitle-font-size: 1em !default;
$legend-font-size: 1em !default;
$axis-labels-font-size: 0.9em !default;

// Tooltip
$tooltip-border: 2px !default;
$tooltip-background: $neutral-color-3 !default;

// Axes
$xaxis-grid-line: 0px !default;

// Range-selector
$range-selector-button-border: 0px !default;
$range-selector-input-text: $neutral-color-80 !default;
$range-selector-input-border: $neutral-color-20 !default;

// Data-labels
$data-label-color: $neutral-color-80 !default;

// Buttons
$context-button-background: $background-color !default;

$highcharts-button-background: $neutral-color-3 !default;
$highcharts-button-border: $neutral-color-20 !default;
$highcharts-button-text:  $neutral-color-80 !default;

$highcharts-button-pressed-background: $highlight-color-10 !default;
$highcharts-button-pressed-border: $neutral-color-20 !default;
$highcharts-button-pressed-text:  $neutral-color-80 !default;

$highcharts-button-hover-background: $neutral-color-10 !default;
$highcharts-button-hover-border: $neutral-color-20 !default;
$highcharts-button-hover-text:  $neutral-color-80 !default;

// Navigator
$navigator-series-fill: $highlight-color-80 !default;
$navigator-series-border: $highlight-color-80 !default;

// Scrollbar
$scrollbar-track-background: $neutral-color-5 !default;
$scrollbar-track-border: $neutral-color-5 !default;

//Legend
.highcharts-legend-item:hover text {
  fill: $anchor-link-color !important;
}

//Fill Area
.highcharts-area {
  fill-opacity: 0.2 !important;
  stroke-width: 0;
}

@import "highcharts/css/highcharts.scss";
