

@import "./node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "./node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "./node_modules/ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss";
@import "./node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";
@import "./node_modules/ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin.scss";
// @import "./node_modules/ag-grid-community/src/styles/ag-theme-blue/sass/ag-theme-blue.scss";
// @import "./node_modules/ag-grid-community/src/styles/ag-theme-bootstrap/sass/ag-theme-bootstrap.scss";
// @import "./node_modules/ag-grid-community/src/styles/ag-theme-dark/sass/ag-theme-dark.scss";
// @import "./node_modules/ag-grid-community/src/styles/ag-theme-fresh/sass/ag-theme-fresh.scss";
@import "./node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

$drk_background_color: #3C3C3C;
//$drk_border_color: #2F2F2F;
$drk_border_color: #504F4F;
$drk_border_color_opaque: rgba($drk_border_color, 0.5);
$drk_header_color: #424242;

$light_border_color_opaque: rgba(#e2e2e2, 0.5);

$grid_size: 4px;

@mixin ag-theme-alpine-dark-base($_grid_size: $grid_size, $_alpine-active-color: #2196f3) {
  @include ag-theme-alpine-dark (
    (
      alpine-active-color: $_alpine-active-color,
      //alpine-active-color: #2196f3,
      background-color: $drk_background_color,
      //foreground-color: #fff,
      border-color: $drk_border_color,
      //secondary-border-color: rgba(#585652, 0.5),
      header-background-color: $drk_header_color,
      odd-row-background-color: $drk_header_color,
      control-panel-background-color: $drk_header_color,
      grid-size: $_grid_size,
      borders: true,
      cell-horizontal-border: solid var(--ag-header-column-resize-handle-color, $drk_border_color_opaque),
      row-border-color: var(--ag-header-column-resize-handle-color, $drk_border_color_opaque),
      icon-size: 14px,
      cell-horizontal-padding: ag-derived(grid-size, $times: 2),
      header-column-resize-handle-height: 100%,
      //subheader-background-color: #000,
      //input-focus-box-shadow: (
      //  // add a second tighter white shadow to make the glow stand out better
      //  0 0 2px 0.5px rgba(255, 255, 255, 0.5),
      //  0 0 4px 3px ag-derived(input-focus-border-color, $opacity: 0.6)
      //),
      //card-shadow: 0 1px 20px 1px black,
    )
  );

}

.ag-theme-alpine-dark {
  @include ag-theme-alpine-dark-base();
}

.ag-theme-alpine-dark-extraspace {
  @include ag-theme-alpine-dark-base(
    $_grid_size: 6px,
  );
}

.ag-theme-alpine-dark-green {
  @include ag-theme-alpine-dark-base(
    $_alpine-active-color: #2d7130,
  );
}

.ag-theme-alpine-dark-pink {
  @include ag-theme-alpine-dark-base(
    $_alpine-active-color: #E63376,
  );
}

.ag-theme-material {
  @include ag-theme-material (
      (
        grid-size: $grid_size,
        borders: true,
        cell-horizontal-border: solid var(--ag-header-column-resize-handle-color, $light_border_color_opaque),
        row-border-color: var(--ag-header-column-resize-handle-color, $light_border_color_opaque),
        icon-size: 14px,
        cell-horizontal-padding: ag-derived(grid-size, $times: 2),
        header-column-resize-handle-height: 100%,   

      )
  );
}

.ag-theme-alpine {
  @include ag-theme-alpine (
      (
        grid-size: $grid_size,
        borders: true,
        cell-horizontal-border: solid var(--ag-header-column-resize-handle-color, $light_border_color_opaque),
        row-border-color: var(--ag-header-column-resize-handle-color, $light_border_color_opaque),
        icon-size: 14px,
        cell-horizontal-padding: ag-derived(grid-size, $times: 2),
        header-column-resize-handle-height: 100%,   
      )
  );
}

.ag-theme-balham {
  @include ag-theme-balham (
      (
      )
  );
}

.ag-theme-balham-dark {
  @include ag-theme-balham-dark (
      (
      )
  );
}

// .ag-theme-blue {
//   @include ag-theme-classic-impl(
//     "ag-theme-blue", (
      
//       )
//   );
// }

// .ag-theme-bootstrap {
//   @include ag-theme-classic-impl(
//     "ag-theme-bootstrap", (
      
//       )
//   );
// }

// .ag-theme-dark {
//   @include ag-theme-classic-impl(
//     "ag-theme-dark", (
//       )
//   );
// }

// .ag-theme-fresh {
//   @include ag-theme-classic-impl(
//     "ag-theme-fresh", (
//       )
//   );
// }


